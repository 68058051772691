exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-en-us-tsx": () => import("./../../../src/pages/about-us.en-US.tsx" /* webpackChunkName: "component---src-pages-about-us-en-us-tsx" */),
  "component---src-pages-about-us-fr-ca-tsx": () => import("./../../../src/pages/about-us.fr-CA.tsx" /* webpackChunkName: "component---src-pages-about-us-fr-ca-tsx" */),
  "component---src-pages-employers-en-us-tsx": () => import("./../../../src/pages/employers.en-US.tsx" /* webpackChunkName: "component---src-pages-employers-en-us-tsx" */),
  "component---src-pages-employers-fr-ca-tsx": () => import("./../../../src/pages/employers.fr-CA.tsx" /* webpackChunkName: "component---src-pages-employers-fr-ca-tsx" */),
  "component---src-pages-get-a-demo-en-us-tsx": () => import("./../../../src/pages/get-a-demo.en-US.tsx" /* webpackChunkName: "component---src-pages-get-a-demo-en-us-tsx" */),
  "component---src-pages-get-a-demo-fr-ca-tsx": () => import("./../../../src/pages/get-a-demo.fr-CA.tsx" /* webpackChunkName: "component---src-pages-get-a-demo-fr-ca-tsx" */),
  "component---src-pages-help-en-us-tsx": () => import("./../../../src/pages/help.en-US.tsx" /* webpackChunkName: "component---src-pages-help-en-us-tsx" */),
  "component---src-pages-help-fr-ca-tsx": () => import("./../../../src/pages/help.fr-CA.tsx" /* webpackChunkName: "component---src-pages-help-fr-ca-tsx" */),
  "component---src-pages-index-en-us-tsx": () => import("./../../../src/pages/index.en-US.tsx" /* webpackChunkName: "component---src-pages-index-en-us-tsx" */),
  "component---src-pages-index-fr-ca-tsx": () => import("./../../../src/pages/index.fr-CA.tsx" /* webpackChunkName: "component---src-pages-index-fr-ca-tsx" */),
  "component---src-pages-privacy-policy-en-us-tsx": () => import("./../../../src/pages/privacy-policy.en-US.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-en-us-tsx" */),
  "component---src-pages-privacy-policy-fr-ca-tsx": () => import("./../../../src/pages/privacy-policy.fr-CA.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-fr-ca-tsx" */),
  "component---src-pages-terms-of-service-en-us-tsx": () => import("./../../../src/pages/terms-of-service.en-US.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-en-us-tsx" */),
  "component---src-pages-terms-of-service-fr-ca-tsx": () => import("./../../../src/pages/terms-of-service.fr-CA.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-fr-ca-tsx" */),
  "component---src-pages-values-en-us-tsx": () => import("./../../../src/pages/values.en-US.tsx" /* webpackChunkName: "component---src-pages-values-en-us-tsx" */),
  "component---src-pages-values-fr-ca-tsx": () => import("./../../../src/pages/values.fr-CA.tsx" /* webpackChunkName: "component---src-pages-values-fr-ca-tsx" */),
  "component---src-views-blog-blog-index-template-tsx": () => import("./../../../src/views/Blog/BlogIndexTemplate.tsx" /* webpackChunkName: "component---src-views-blog-blog-index-template-tsx" */),
  "component---src-views-blog-blog-template-tsx": () => import("./../../../src/views/Blog/BlogTemplate.tsx" /* webpackChunkName: "component---src-views-blog-blog-template-tsx" */)
}

